import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout/layout'

const About = () => (
  <Layout>
    <div id='main'>
      <section id='abstract'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='global-page-header text-center'>
                <div className='clearfix'>
                  <h2>About Hugo</h2>
                  <p>
                    Hello and welcome. I'm Hugo Elizandro (28), 
                    front end developer with a degree in Internet Systems 
                    from Universidade do Vale do Itajai (Univali University).
                  </p>
                  <p>
                    I've got {new Date().getFullYear() - 2013}+ years 
                    experience writing maintainable code. Good communicator 
                    and detail-oriented with an appreciation for good design.
                  </p>
                  <p>See my LinkedIn profile at:</p>
                  <p>
                    <a 
                      target='_blank'
                      rel='noopener noreferrer'
                      href='https://www.linkedin.com/in/hugoelizandro/' 
                    >
                      linkedin.com/in/hugoelizandro
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-12'>
              <div className='line-bottom text-center'>
                <Link to='/' className='btn btn-primary'>Home</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
)

export default About
